<template lang="pug">
    #BoxRevista
        ul
            li(v-for="item in boxes")
                a(:href="item.link" target="_blank")
                    .img
                        img(:src="item.image").image
                        //- SvgIcon(data="@svgs/play_white.svg", original).play
                    p {{ item.text }}
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "box-revistas",
    props,
    data() {
        return {
            boxes: []
        }
    },
    mounted() {
        this.boxes = this.contents.content.map(item => ({
            ...item,
            image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
        }))
    }

}
</script>

<style lang="stylus" scoped src="./BoxR.styl"></style>
